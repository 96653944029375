import jwtDecode from 'jwt-decode';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { EazyWinBallsCounts, MetroBallsCounts, Six49BallsCounts } from './lotteryCategoryCount';
import { parseCookies } from 'nookies';

dayjs.extend(utc);
dayjs.extend(timezone);

export const FULL_DATE_FORMAT = 'DD MMM YYYY, HH:mm a';
export const allTransactionTypes = {
    DEPOSIT: 'deposit',
    CHARGE: 'charge',
    WINNING: 'winning',
    COMMISSION: 'commission',
    WITHDRAWAL: 'withdrawal',
};

export const creditTransactionTypes = [
    allTransactionTypes.DEPOSIT,
    allTransactionTypes.WINNING,
    allTransactionTypes.COMMISSION,
];
export const debitTransactionTypes = [allTransactionTypes.CHARGE, allTransactionTypes.WITHDRAWAL];

export const BET_TYPE_SELECT = 'betTypeSelect';
export const OVER = 'overType';
export const OVER_ADVANCE = 'overTypeAdvance';
export const UNDER = 'underType';
export const UNDER_ADVANCE = 'underTypeAdvance';

export const DAILY = 'DAILY';
export const WEEKLY = 'WEEKLY';
export const MONTHLY = 'MONTHLY';

export const WHITE_USER_BAL = 'WHITE_USER_BAL';
export const WHITE_USER_ID = 'WHITE_USER_ID';

export const ADVANCE_OVER_TYPE = 'advancedOverType';
export const ADVANCE_UNDER_TYPE = 'advancedUnderType';
export const SESSION_OVER_UNDER_OPTION = 'SESSION_OVER_UNDER_OPTION';
export const TOKEN_NAME = 'somedata';
export const ACTIVE_GAME = 'ACTIVE_GAME';
export const BANK_LIST = 'BANK_LIST';
export const BANK_USAGE_COUNT = 'BANK_USAGE_COUNT';

export const SELECTED_BETOPTION = 'SELECTED_BETOPTION';
export const ALL_LOTTERIES_BETOPTIONS = 'ALL_LOTTERIES_BETOPTIONS';
export const SELECTED_LOTTERY_BETOPTIONS = 'SELECTED_LOTTERY_BETOPTIONS';
export const SELECTED_LOTTERY_RESOPTIONS = 'SELECTED_LOTTERY_RESOPTIONS';
export const SELECTED_LOTTERY_BOOSTEROPTIONS = 'SELECTED_LOTTERY_BOOSTEROPTIONS';

export const SALARY_FOR_LIFE = 'salary4life';
export const SALARY_FOR_LIFE_BETOPTIONS = 'salary-for-life-bet-options';

export const MEGA_7 = 'mega-7';
export const MEGA_7_BETOPTIONS = 'mega-7-bet-options';
export const LEGENDARY_LOTTO = 'legendarylotto';
export const LEGENDARY_LOTTO_BETOPTIONS = 'legendary-lotto-bet-options';

export const FIVE_0F_NINETY = '5of90';
export const FIVE_0F_NINETY_BETOPTIONS = 'five-ninety-bet-options';

export const AFRIKENO = 'afrikeno';
export const AFRIKENO_BETOPTIONS = 'afrikeno-bet-options';
export const AFRIKENO_OVEROPTIONS = 'afrikeno-over-bet-options';
export const AFRIKENO_UNDEROPTIONS = 'afrikeno-under-bet-options';

export const EAZY_WIN = 'eazy-win';
export const EAZY_WIN_OVEROPTIONS = 'eazy-win-over-bet-options';
export const EAZY_WIN_UNDEROPTIONS = 'eazy-win-under-bet-options';
export const EAZY_WIN_BETOPTIONS = 'eazy-win-bet-options';
export const KENO = 'keno';
export const KENO_BETOPTIONS = 'keno-bet-options';
export const SIX_FOUR_NINE = 'six49';
export const SIX_FOUR_NINE_BETOPTIONS = 'six49-bet-options';
export const METRO = 'metro';
export const SEVEN49 = 'seven49';
export const SEVEN49_BETOPTIONS = 'seven49-bet-options';
export const SEVEN49_OVEROPTIONS = 'seven49-over-bet-options';
export const SEVEN49_UNDEROPTIONS = 'seven49-under-bet-options';

export const META_4 = 'meta4';
export const META_4_BETOPTIONS = 'meta4-bet-options';
export const META_4_OVEROPTIONS = 'meta4-over-bet-options';
export const META_4_UNDEROPTIONS = 'meta4-under-bet-options';

export const CASHOUT = 'cash-out';
export const CASHOUT_BETOPTIONS = 'cash-out-bet-options';

export const PERFECT_BALLS = 'perfect-balls';
export const PERFECT_BALLS_BETOPTIONS = 'perfect-balls-bet-options';

export const LOTTO = 'lotto';
export const LOTTO_BETOPTIONS = 'lotto-bet-options';
export const LOTTO_RESULTOPTIONS = 'lotto-result-options';
export const LOTTO_OVEROPTIONS = 'lotto-over-bet-options';
export const LOTTO_UNDEROPTIONS = 'lotto-under-bet-options';

export const METRO_BETOPTIONS = 'metro-bet-options';
export const METRO_OVEROPTIONS = 'metro-over-bet-options';
export const METRO_UNDEROPTIONS = 'metro-under-bet-options';

export const INDOOR_GAME = 'indoor-game';
export const INDOOR_BET_OPTIONS = 'indoor-game-bet-options';
export const INDOOR_RESULT_OPTIONS = 'indoor-game-result-types';
export const INDOOR_OVER_OPTIONS = 'indoor-game-over-bet-options';
export const INDOOR_UNDER_OPTIONS = 'indoor-game-under-bet-options';
export const INDOOR_BOOSTER_OPTIONS = 'indoor-game-booster-options';

export const GHANA_GAME = 'ghana-game';
export const GHANA_BET_OPTIONS = 'ghana-game-bet-options';
export const GHANA_RESULT_OPTIONS = 'ghana-game-result-types';
export const GHANA_OVER_OPTIONS = 'ghana-game-over-bet-options';
export const GHANA_UNDER_OPTIONS = 'ghana-game-under-bet-options';
export const GHANA_BOOSTER_OPTIONS = 'ghana-game-booster-options';

export const POOL_OF_FAME = 'pool-of-fame';
export const POOL_OF_FAME_BETOPTIONS = 'pool-of-fame-bet-options';

export const LOTTO_CONTINENTAL = 'lotto-continental';
export const LOTTO_CONTINENTAL_RESULT_OPTIONS = 'lotto-continental-result-options';
export const LOTTO_CONTINENTAL_BET_OPTIONS = 'lotto-continental-bet-options';

export const MARIO_KENO = 'mario-keno';
export const MARIO_KENO_BETOPTIONS = 'mario-keno-bet-options';

export const BINGO_BALLS = 'bingo-balls';
export const BINGO_BALLS_OVER_OPTIONS = 'bingo-balls-over-bet-options';
export const BINGO_BALLS_UNDER_OPTIONS = 'bingo-balls-under-bet-options';
export const BINGO_BALLS_BETOPTIONS = 'bingo-balls-bet-options';

export const ENGINE_URL = 'https://engine.gaim.tech/white-label';
export const EVENT_NEW_GAME_RESULT = 'NEW_GAME_RESULT';
export const WGB = 'WGB';
export const BET_9JA = 'BET_9JA';

export const INSTANT_METHOD = 'instant';

export const Six49_RED_COLOR = '#d80101';
export const Six49_BLUE_COLOR = '#0c01d8';
export const Six49_YELLOW_COLOR = '#d7b405';
export const BLUE_COLOR = 'BLUE';
export const YELLOW_COLOR = 'YELLOW';
export const RED_COLOR = 'RED';
export const BLACK_COLOR = 'BLACK';
export const fiveNinetyGamesArray = [LOTTO_CONTINENTAL, FIVE_0F_NINETY, LEGENDARY_LOTTO, LOTTO];
export const BingoBallsCounts = [
    {
        num: 1,
        bg: 'linear-gradient(45deg, rgba(138,17,1,1) 0%, rgba(231,16,0,1) 44%, rgba(129,0,0,1) 100%)',
    },

    {
        num: 2,
        bg: 'linear-gradient(45deg, rgba(240,237,17,1) 0%, rgba(231,197,0,1) 44%, rgba(255,166,0,1) 100%)',
    },
    {
        num: 3,
        bg: 'linear-gradient(45deg, rgba(0,41,196,1) 0%, rgba(0,179,231,1) 44%, rgba(25,0,255,1) 100%)',
    },

    {
        num: 4,
        bg: 'linear-gradient(45deg, rgba(119,31,2,1) 0%, rgba(135,38,0,1) 44%, rgba(175,79,0,1) 100%)',
    },

    {
        num: 5,
        bg: 'linear-gradient(45deg, rgba(20,193,0,1) 0%, rgba(0,135,4,1) 44%, rgba(5,219,54,1) 100%)',
    },

    {
        num: 6,
        bg: 'linear-gradient(45deg, rgba(181,0,193,1) 0%, rgba(113,0,135,1) 44%, rgba(229,0,212,1) 100%)',
    },
    {
        num: 7,
        bg: 'linear-gradient(45deg, rgba(255,2,97,1) 0%, rgba(253,0,69,1) 44%, rgba(229,0,112,1) 100%)',
    },
    {
        num: 8,
        bg: 'linear-gradient(45deg, rgba(107,106,106,1) 0%, rgba(57,54,55,1) 49%, rgba(189,189,189,1) 100%)',
    },

    {
        num: 9,
        bg: 'linear-gradient(45deg, rgba(138,17,1,1) 0%, rgba(231,16,0,1) 44%, rgba(129,0,0,1) 100%)',
    },
    {
        num: 10,
        bg: 'linear-gradient(45deg, rgba(240,237,17,1) 0%, rgba(231,197,0,1) 44%, rgba(255,166,0,1) 100%)',
    },
    {
        num: 11,
        bg: 'linear-gradient(45deg, rgba(0,41,196,1) 0%, rgba(0,179,231,1) 44%, rgba(25,0,255,1) 100%)',
    },
    {
        num: 12,
        bg: 'linear-gradient(45deg, rgba(119,31,2,1) 0%, rgba(135,38,0,1) 44%, rgba(175,79,0,1) 100%)',
    },
    {
        num: 13,
        bg: 'linear-gradient(45deg, rgba(20,193,0,1) 0%, rgba(0,135,4,1) 44%, rgba(5,219,54,1) 100%)',
    },
    {
        num: 14,
        bg: 'linear-gradient(45deg, rgba(181,0,193,1) 0%, rgba(113,0,135,1) 44%, rgba(229,0,212,1) 100%)',
    },
    {
        num: 15,
        bg: 'linear-gradient(45deg, rgba(255,2,97,1) 0%, rgba(253,0,69,1) 44%, rgba(229,0,112,1) 100%)',
    },
    {
        num: 16,
        bg: 'linear-gradient(45deg, rgba(107,106,106,1) 0%, rgba(57,54,55,1) 49%, rgba(189,189,189,1) 100%)',
    },

    {
        num: 17,
        bg: 'linear-gradient(45deg, rgba(138,17,1,1) 0%, rgba(231,16,0,1) 44%, rgba(129,0,0,1) 100%)',
    },
    {
        num: 18,
        bg: 'linear-gradient(45deg, rgba(240,237,17,1) 0%, rgba(231,197,0,1) 44%, rgba(255,166,0,1) 100%)',
    },
    {
        num: 19,
        bg: 'linear-gradient(45deg, rgba(0,41,196,1) 0%, rgba(0,179,231,1) 44%, rgba(25,0,255,1) 100%)',
    },
    {
        num: 20,
        bg: 'linear-gradient(45deg, rgba(119,31,2,1) 0%, rgba(135,38,0,1) 44%, rgba(175,79,0,1) 100%)',
    },
    {
        num: 21,
        bg: 'linear-gradient(45deg, rgba(20,193,0,1) 0%, rgba(0,135,4,1) 44%, rgba(5,219,54,1) 100%)',
    },
    {
        num: 22,
        bg: 'linear-gradient(45deg, rgba(181,0,193,1) 0%, rgba(113,0,135,1) 44%, rgba(229,0,212,1) 100%)',
    },
    {
        num: 23,
        bg: 'linear-gradient(45deg, rgba(255,2,97,1) 0%, rgba(253,0,69,1) 44%, rgba(229,0,112,1) 100%)',
    },
    {
        num: 24,
        bg: 'linear-gradient(45deg, rgba(107,106,106,1) 0%, rgba(57,54,55,1) 49%, rgba(189,189,189,1) 100%)',
    },

    {
        num: 25,
        bg: 'linear-gradient(45deg, rgba(138,17,1,1) 0%, rgba(231,16,0,1) 44%, rgba(129,0,0,1) 100%)',
    },
    {
        num: 26,
        bg: 'linear-gradient(45deg, rgba(240,237,17,1) 0%, rgba(231,197,0,1) 44%, rgba(255,166,0,1) 100%)',
    },
    {
        num: 27,
        bg: 'linear-gradient(45deg, rgba(0,41,196,1) 0%, rgba(0,179,231,1) 44%, rgba(25,0,255,1) 100%)',
    },
    {
        num: 28,
        bg: 'linear-gradient(45deg, rgba(119,31,2,1) 0%, rgba(135,38,0,1) 44%, rgba(175,79,0,1) 100%)',
    },
    {
        num: 29,
        bg: 'linear-gradient(45deg, rgba(20,193,0,1) 0%, rgba(0,135,4,1) 44%, rgba(5,219,54,1) 100%)',
    },
    {
        num: 30,
        bg: 'linear-gradient(45deg, rgba(181,0,193,1) 0%, rgba(113,0,135,1) 44%, rgba(229,0,212,1) 100%)',
    },
    {
        num: 31,
        bg: 'linear-gradient(45deg, rgba(255,2,97,1) 0%, rgba(253,0,69,1) 44%, rgba(229,0,112,1) 100%)',
    },
    {
        num: 32,
        bg: 'linear-gradient(45deg, rgba(107,106,106,1) 0%, rgba(57,54,55,1) 49%, rgba(189,189,189,1) 100%)',
    },

    {
        num: 33,
        bg: 'linear-gradient(45deg, rgba(138,17,1,1) 0%, rgba(231,16,0,1) 44%, rgba(129,0,0,1) 100%)',
    },
    {
        num: 34,
        bg: 'linear-gradient(45deg, rgba(240,237,17,1) 0%, rgba(231,197,0,1) 44%, rgba(255,166,0,1) 100%)',
    },
    {
        num: 35,
        bg: 'linear-gradient(45deg, rgba(0,41,196,1) 0%, rgba(0,179,231,1) 44%, rgba(25,0,255,1) 100%)',
    },
    {
        num: 36,
        bg: 'linear-gradient(45deg, rgba(119,31,2,1) 0%, rgba(135,38,0,1) 44%, rgba(175,79,0,1) 100%)',
    },
    {
        num: 37,
        bg: 'linear-gradient(45deg, rgba(20,193,0,1) 0%, rgba(0,135,4,1) 44%, rgba(5,219,54,1) 100%)',
    },
    {
        num: 38,
        bg: 'linear-gradient(45deg, rgba(181,0,193,1) 0%, rgba(113,0,135,1) 44%, rgba(229,0,212,1) 100%)',
    },
    {
        num: 39,
        bg: 'linear-gradient(45deg, rgba(255,2,97,1) 0%, rgba(253,0,69,1) 44%, rgba(229,0,112,1) 100%)',
    },
    {
        num: 40,
        bg: 'linear-gradient(45deg, rgba(107,106,106,1) 0%, rgba(57,54,55,1) 49%, rgba(189,189,189,1) 100%)',
    },

    {
        num: 41,
        bg: 'linear-gradient(45deg, rgba(138,17,1,1) 0%, rgba(231,16,0,1) 44%, rgba(129,0,0,1) 100%)',
    },
    {
        num: 42,
        bg: 'linear-gradient(45deg, rgba(240,237,17,1) 0%, rgba(231,197,0,1) 44%, rgba(255,166,0,1) 100%)',
    },
    {
        num: 43,
        bg: 'linear-gradient(45deg, rgba(0,41,196,1) 0%, rgba(0,179,231,1) 44%, rgba(25,0,255,1) 100%)',
    },
    {
        num: 44,
        bg: 'linear-gradient(45deg, rgba(119,31,2,1) 0%, rgba(135,38,0,1) 44%, rgba(175,79,0,1) 100%)',
    },
    {
        num: 45,
        bg: 'linear-gradient(45deg, rgba(20,193,0,1) 0%, rgba(0,135,4,1) 44%, rgba(5,219,54,1) 100%)',
    },
    {
        num: 46,
        bg: 'linear-gradient(45deg, rgba(181,0,193,1) 0%, rgba(113,0,135,1) 44%, rgba(229,0,212,1) 100%)',
    },
    {
        num: 47,
        bg: 'linear-gradient(45deg, rgba(255,2,97,1) 0%, rgba(253,0,69,1) 44%, rgba(229,0,112,1) 100%)',
    },
    {
        num: 48,
        bg: 'linear-gradient(45deg, rgba(107,106,106,1) 0%, rgba(57,54,55,1) 49%, rgba(189,189,189,1) 100%)',
    },
    {
        num: 49,
        bg: '#000000',
    },
];

export const KenoBallsCounts = [
    {
        num: 1,
        bg: '#ffffff',
    },

    {
        num: 2,
        bg: '#ffffff',
    },
    {
        num: 3,
        bg: '#ffffff',
    },

    {
        num: 4,
        bg: '#ffffff',
    },

    {
        num: 5,
        bg: '#ffffff',
    },

    {
        num: 6,
        bg: '#ffffff',
    },
    {
        num: 7,
        bg: '#ffffff',
    },
    {
        num: 8,
        bg: '#ffffff',
    },

    {
        num: 9,
        bg: '#ffffff',
    },
    {
        num: 10,
        bg: '#000000',
    },
    {
        num: 11,
        bg: '#ffffff',
    },
    {
        num: 12,
        bg: '#ffffff',
    },
    {
        num: 13,
        bg: '#ffffff',
    },
    {
        num: 14,
        bg: '#ffffff',
    },
    {
        num: 15,
        bg: '#ffffff',
    },
    {
        num: 16,
        bg: '#ffffff',
    },

    {
        num: 17,
        bg: '#ffffff',
    },
    {
        num: 18,
        bg: '#ffffff',
    },
    {
        num: 19,
        bg: '#ffffff',
    },
    {
        num: 20,
        bg: '#000000',
    },
    {
        num: 21,
        bg: '#ffffff',
    },
    {
        num: 22,
        bg: '#ffffff',
    },
    {
        num: 23,
        bg: '#ffffff',
    },
    {
        num: 24,
        bg: '#ffffff',
    },

    {
        num: 25,
        bg: '#ffffff',
    },
    {
        num: 26,
        bg: '#ffffff',
    },
    {
        num: 27,
        bg: '#ffffff',
    },
    {
        num: 28,
        bg: '#ffffff',
    },
    {
        num: 29,
        bg: '#ffffff',
    },
    {
        num: 30,
        bg: '#000000',
    },
    {
        num: 31,
        bg: '#ffffff',
    },
    {
        num: 32,
        bg: '#ffffff',
    },

    {
        num: 33,
        bg: '#ffffff',
    },
    {
        num: 34,
        bg: '#ffffff',
    },
    {
        num: 35,
        bg: '#ffffff',
    },
    {
        num: 36,
        bg: '#ffffff',
    },
    {
        num: 37,
        bg: '#ffffff',
    },
    {
        num: 38,
        bg: '#ffffff',
    },
    {
        num: 39,
        bg: '#ffffff',
    },
    {
        num: 40,
        bg: '#000000',
    },

    {
        num: 41,
        bg: '#ffffff',
    },
    {
        num: 42,
        bg: '#ffffff',
    },
    {
        num: 43,
        bg: '#ffffff',
    },
    {
        num: 44,
        bg: '#ffffff',
    },
    {
        num: 45,
        bg: '#ffffff',
    },
    {
        num: 46,
        bg: '#ffffff',
    },
    {
        num: 47,
        bg: '#ffffff',
    },
    {
        num: 48,
        bg: '#ffffff',
    },
    {
        num: 49,
        bg: '#ffffff',
    },
    {
        num: 50,
        bg: '#000000',
    },

    {
        num: 51,
        bg: '#ffffff',
    },
    {
        num: 52,
        bg: '#ffffff',
    },
    {
        num: 53,
        bg: '#ffffff',
    },
    {
        num: 54,
        bg: '#ffffff',
    },
    {
        num: 55,
        bg: '#ffffff',
    },
    {
        num: 56,
        bg: '#ffffff',
    },
    {
        num: 57,
        bg: '#ffffff',
    },
    {
        num: 58,
        bg: '#ffffff',
    },
    {
        num: 59,
        bg: '#ffffff',
    },
    {
        num: 60,
        bg: '#000000',
    },
    {
        num: 61,
        bg: '#ffffff',
    },
    {
        num: 62,
        bg: '#ffffff',
    },
    {
        num: 63,
        bg: '#ffffff',
    },
    {
        num: 64,
        bg: '#ffffff',
    },
    {
        num: 65,
        bg: '#ffffff',
    },
    {
        num: 66,
        bg: '#ffffff',
    },
    {
        num: 67,
        bg: '#ffffff',
    },
    {
        num: 68,
        bg: '#ffffff',
    },
    {
        num: 69,
        bg: '#ffffff',
    },
    {
        num: 70,
        bg: '#000000',
    },

    {
        num: 71,
        bg: '#ffffff',
    },
    {
        num: 72,
        bg: '#ffffff',
    },
    {
        num: 73,
        bg: '#ffffff',
    },
    {
        num: 74,
        bg: '#ffffff',
    },
    {
        num: 75,
        bg: '#ffffff',
    },
    {
        num: 76,
        bg: '#ffffff',
    },
    {
        num: 77,
        bg: '#ffffff',
    },
    {
        num: 78,
        bg: '#ffffff',
    },
    {
        num: 79,
        bg: '#ffffff',
    },
    {
        num: 80,
        bg: '#000000',
    },
];

export const NoSelectionBetTypes = [
    'single',
    'double',
    'odd',
    'even',
    'sum-over',
    'sum-under',
    '1st-over',
    'last-over',
    '1st-under',
    'last-under',
];
export const ROLES = {
    PLAYER: 'player',
    INFLUENCER: 'influencer',
    AGENT: 'agent',
};
export const formControlStyle = {
    padding: '10px 10px',
    marginBottom: '2%',
    borderRaius: '20px',
};
export const toolBarStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};
export const getUserToken = () => {
    if (typeof window === 'object') {
        const token = localStorage.getItem(TOKEN_NAME);

        if (!token) return null;
        return token;
    }
};
export const getAwayUserBal = () => {
    const appCookies = parseCookies();

    let bal = '';

    if (appCookies && appCookies[WHITE_USER_BAL]) {
        bal = appCookies[WHITE_USER_BAL];
    }

    if (!bal) return null;
    return bal;
};

export const getAwayUserId = () => {
    const appCookies = parseCookies();

    let userId = '';

    if (appCookies && appCookies[WHITE_USER_ID]) {
        userId = appCookies[WHITE_USER_ID];
    }

    if (!userId) return null;
    return userId;
};

export const generateRandomId = () => {
    // program to generate random strings

    const result = `${Math.random().toString(36).substring(2, 9)}${Math.random()
        .toString(36)
        .substring(2, 9)}`;
    return result;
};

export const getLotteryTVTimerId = (lotteryTVIds, lottery) => {
    const res = lotteryTVIds?.find((item) => item?.name === lottery);

    return res;
};
export const renderOverUnderValue = (item, value = 'name') => {
    if (typeof item === 'string') return item;
    if (item !== null) {
        return item[value];
    }
    console.log('item', item);
    return '';
};

export const getSessionStorageOverUnderOption = () => {
    if (typeof window !== 'undefined') {
        let res = sessionStorage.getItem(SESSION_OVER_UNDER_OPTION);
        res = JSON.parse(res);
        return res;
    }
};
export const setSessionStorageOverUnderOption = (value) => {
    if (typeof window !== 'undefined') {
        sessionStorage.setItem(SESSION_OVER_UNDER_OPTION, value);
    }
};
export const removeSessionStorageOverUnderOption = () => {
    if (typeof window !== 'undefined') {
        sessionStorage.removeItem(SESSION_OVER_UNDER_OPTION);
        sessionStorage.setItem(SESSION_OVER_UNDER_OPTION, null);
    }
};
export const extractTypeFromAdvanceTypes = (value) => {
    let field = value?.split(' ')[1];
    let newField = field?.slice(1, field?.length - 1);
    return newField;
};
export const findWGBOverUnderOption = (data = [], value = 'name') => {
    const res = data?.find((item) => item?.name?.toLowerCase()?.includes(value?.toLowerCase()));
    return res;
    // return true;
};

export const findKenoBetOption = (data = [], value = 'name') => {
    const res = data?.find(
        (item) =>
            item?.name?.toLowerCase() === value?.toLowerCase() ||
            item?.value?.toLowerCase() === value?.toLowerCase(),
    );
    return res;
    // return true;
};
export const getActiveGameFromStorage = () => {
    try {
        let game = '';
        if (typeof window === 'object') {
            let gottenGame = sessionStorage.getItem(ACTIVE_GAME);

            if (gottenGame && typeof gottenGame !== 'undefined') {
                game = JSON?.parse(gottenGame);
            }
        }
        return game;
    } catch (error) {
        return '';
    }
};

export const findWGBBall = (value) => {
    const res = BingoBallsCounts.find((item) => item.num === Number(value));
    return res;
};
export const find649Ball = (value) => {
    const res = Six49BallsCounts.find((item) => item.num === Number(value));
    return res;
};
export const findEazyWinBall = (value) => {
    const res = EazyWinBallsCounts.find((item) => item.num === Number(value));
    return res;
};
export const findMetroBall = (value) => {
    const res = MetroBallsCounts.find((item) => item.num === Number(value));
    return res;
};
// export const findMarioKenoBall = (value) => {
//     const res = MarioKeno.find((item) => item.num === Number(value));
//     return res;
// };

export const checkIfFieldIsEmpty = (data = []) => {
    let isEmpty = true;

    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (element === '') {
            isEmpty = true;
            break;
        } else {
            isEmpty = false;
        }
    }
    return isEmpty;
};
export const isNoSelectionBetType = (betType) => {
    const regex =
        /^single.|^double.|odd$|even$|sum-over|sum-under|1st-over|last-over|1st-under|last-under|^most-|-single$|-double$/i;

    // let newGameOptions = betOptions?.filter(
    //     (item) => regex.test(item?.name) || regex.test(item?.value),
    // );
    let selectedType = betType || getBetOptionFromStorage()?.value;
    let res = regex?.test(selectedType);
    return res;
};
// Check Expired Token
export const checkExpiredUserToken = () => {
    try {
        const token = getUserToken();

        if (!token) {
            // toast.error('Your Login Session Have Expired');
            // window.location.href = '/login';
            localStorage.removeItem(TOKEN_NAME);
            return toast.error('No token provided');
        }
        const decodedToken = jwtDecode(token);

        if (typeof window === 'object') {
            const tokenExpired = decodedToken.exp < new Date().getTime() / 1000;

            if (tokenExpired) {
                toast.error('Your Login Session Have Expired');
                // window.location.href = '/login';
                localStorage.removeItem(TOKEN_NAME);
            }
        }
    } catch (error) {
        console.log('err', error);
    }
};

// To safely JSON.parse a string without breaking the application
export const safeJSONParse = (str) => {
    try {
        let resultArray = [];
        let parseArray = [];
        if (typeof str === 'undefined') {
            return resultArray;
        }
        parseArray = JSON?.parse(str);
        for (const key in parseArray) {
            if (Object.hasOwnProperty.call(parseArray, key)) {
                const element = parseArray[key];
                // console.log('keyy', element);
                resultArray.push(element);
            }
        }

        return resultArray;
    } catch (error) {
        console.log('err while parseing', error);
    }
};

export const safeJSONParseObj = (str) => {
    try {
        let resultObj = {};

        if (typeof str === 'undefined') {
            return resultObj;
        }
        resultObj = JSON.parse(str);

        return resultObj;
    } catch (error) {
        console.log('err while parseing', error);
    }
};

// To format a time

export const formatGameDate = (time, addSeconds = false, gameType = 'normal') => {
    if (gameType === 'normal') {
        if (time) {
            let splitedTime = time.split(':');
            let date = new Date();
            date.setHours(Number(splitedTime[0]) + 1);
            date.setMinutes(Number(splitedTime[1]));
            date.setSeconds(Number(splitedTime[2]));

            if (addSeconds) {
                return dayjs(date).utc().tz('Africa/Lagos').format('HH:mm:ss');
            }
            return dayjs(date).utc().tz('Africa/Lagos').format('HH:mm');

            // if (addSeconds) {
            //     return dayjs(date).format('HH:mm:ss');
            // }
            // return dayjs(date).format('HH:mm');
        }
    }
    if (gameType === 'instant') {
        if (time) {
            let splitedTime = time.split(':');
            let date = new Date();
            date.setHours(Number(splitedTime[0]) + 1);
            date.setMinutes(Number(splitedTime[1]));
            date.setSeconds(Number(splitedTime[2]));

            if (addSeconds) {
                return dayjs(date).utc().tz('Africa/Lagos').format('HH:mm:ss');
            }
            return dayjs(date).utc().tz('Africa/Lagos').format('HH:mm');
        }
    }
};
export const formatGameDateByTz = (time = new Date(), region = 'Africa/Lagos') => {
    if (time) {
        return dayjs(time).utc().tz(region);
    }
};

export const parseStringToArray = (str) => {
    if (str && typeof str === 'string') {
        let splitedArr = str?.split('-');
        splitedArr = splitedArr?.map((item) => parseInt(item));
        return splitedArr;
    }
    return [];
};

export const padWithZeros = (value) => {
    let str = value?.toString();
    let result;
    if (str?.length === 1) {
        result = `0${str}`;
        return result;
    } else {
        return str;
    }
};

export const getOddAndEvenCounts = (selectedBalls = []) => {
    let oddCount = 0;
    let evenCount = 0;
    selectedBalls?.map((item) => {
        if (item % 2 === 0) {
            evenCount += 1;
        }
        if (item % 2 === 1) {
            oddCount += 1;
        }
    });
    return {
        oddCount,
        evenCount,
    };
};

export const isOdd = (number) => {
    if (number % 2 === 1) return true;
    return false;
};
export const isEven = (number) => {
    if (number % 2 === 1) return false;
    return true;
};
export function findMinAmount(betSlips) {
    let Arr = [];

    betSlips?.map((item) => {
        Arr.push(item?.amount);
    });
    let minValue = Math.min(...Arr);
    // let maxValue = Math.max(...Arr);

    return minValue;
}

export function currencyFormater(x, dp = 0) {
    if (!x) {
        return '0';
    }
    let parsedString = parseFloat(x?.toString()).toFixed(dp);
    return parsedString?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export const currencyFormater2 = (x) => {
    if (!x) {
        return 0;
    }
    const formatCash = Intl.NumberFormat('en-US', {
        notation: 'compact',
        maximumFractionDigits: 1,
    }).format(+x);
    return formatCash;
};
export const filterWeeklyGames = (games, weekday) => {
    let filtered = [];
    // console.log('gggggggggggggggggggg', games);
    if (typeof games === 'object') {
        for (const item in games) {
            // console.log('iiiiiiiiiiiiiiiiiiiiiiiiiii', games[item]);
            let gameObj = games[item];
            if (Number(gameObj?.weekday) == weekday) {
                filtered?.push(gameObj);
            }
        }
    } else {
        filtered = games?.filter((game) => Number(game?.weekday) == weekday);
    }
    return filtered;
};
export const filterBanners = (banners, category) => {
    let filtered = [];
    if (typeof banners === 'object') {
        for (const item in banners) {
            // console.log('iiiiiiiiiiiiiiiiiiiiiiiiiii', banners[item]);
            let gameObj = banners[item];
            // console.log('objesssssssssss', gameObj?.category);
            if (gameObj?.category === category) {
                filtered?.push(gameObj);
            }
        }
    } else {
        filtered = banners?.filter((game) => game?.category == category);
    }
    return filtered;
};

export const getArrays = (contents) => {
    let filtered = [];
    if (typeof contents === 'object') {
        for (const item in contents) {
            let gameObj = contents[item];

            filtered?.push(gameObj);
        }
    } else {
        filtered = [...contents];
    }
    return filtered;
};

export const formFieldStyle = {
    padding: '10px 10px',
    marginBottom: '2%',
    borderRaius: '20px',
};

export function generateRandomIntegerInRange(min = 1, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
export const getSumFromObj = (data) => {
    let total = 0;

    for (const item in data) {
        let elem = data[item];
        total += elem;
    }

    return total;
};
export function convertToArray(elements) {
    if (typeof elements === 'array') return elements;

    if (typeof elements === 'string') console.log(elements.split(','));
    return new Array(elements);
}

export function getBetOptionFromStorage() {
    let option = {};
    if (typeof window === 'object') {
        try {
            let res = JSON.parse(sessionStorage.getItem(SELECTED_BETOPTION));
            if (res) {
                option = res;
            }
        } catch (error) {
            console.log(error);
        }
    }
    return option;
}

export const getCurrentGame = (games) => {
    const today = new Date().getDay();
    let currentGames = [];
    let upcommingGames = [];
    let todayGames = games?.filter((game) => game?.weekday === today || game?.recurring);
    let currentHour = new Date().getHours();

    currentGames = todayGames
        ?.filter((game) => {
            let startTimeArray = game?.startTime?.split(':');
            let endTimeArray = game?.endTime?.split(':');

            return (
                Number(startTimeArray[0]) <= currentHour || currentHour > Number(endTimeArray[0])
            );
        })
        ?.sort((a, b) => Number(a?.startTime?.split(':')) - Number(b?.startTime?.split(':')));
    // console.log("current games", currentGames);
    upcommingGames = todayGames
        ?.filter((game) => {
            let startTimeArray = game?.startTime?.split(':');

            return Number(startTimeArray[0]) > currentHour;
        })
        ?.sort((a, b) => Number(a?.startTime?.split(':')) - Number(b?.startTime?.split(':')));
    // console.log("upcomming", upcommingGames);
    return currentGames[0] || upcommingGames[0];
};

export const renderCategoryDisplay = (category) => {
    let display = '';
    if (category === AFRIKENO) return 'Afrikeno';
    if (category === CASHOUT) return 'Cash Out';
    if (category === MEGA_7) return 'Mega 7';
    if (category === LOTTO) return 'Lotto';
    if (category === META_4) return 'Meta 4';
    if (category === SALARY_FOR_LIFE) return 'Salary 4 Life';
    if (category === LEGENDARY_LOTTO) return 'Legendary Lotto';
    if (category === FIVE_0F_NINETY) return '5 of 90';
    if (category === KENO) return 'Keno';
    if (category === EAZY_WIN) return 'Eazy Win';
    if (category === PERFECT_BALLS) return 'Perfect Balls';
    if (category === METRO) return 'Metro';

    if (category === SIX_FOUR_NINE) return '649';
    if (category === SEVEN49) return '749';

    if (category === POOL_OF_FAME) return 'Pool of Fame';
    if (category === MARIO_KENO) return 'Mario Keno';
    if (category === LOTTO_CONTINENTAL) return 'Lotto Continental';
    if (category === BINGO_BALLS) return 'Bingo Balls';
    return display;
};

export const renderHostURL = () => {
    if (typeof window !== 'undefined') {
        return `${window.location.protocol}//${window.location?.host}`;
    }
    return '';
};

export const getWindowQueryString = (name) => {
    let result = '';
    if (typeof window === 'object') {
        const urlParams = new URLSearchParams(window.location.search);
        result = urlParams.get(name);
    }
    return result;
};

export const getNextRunningGame = (games) => {
    // console.log("ggggggggggggggggggggg", games);
    let currentGames = [];
    let mostRecent = games[0];
    let currentHour = new Date().getHours();

    currentGames = games?.filter((game) => {
        let startTimeArray = game?.endTime?.split(':');

        return Number(startTimeArray[0]) >= currentHour;
    });

    // console.log("current games", currentGames);

    for (let index = 0; index < currentGames.length; index++) {
        let element1 = currentGames[index];

        let firstTimeHour = parseInt(element1?.endTime?.split(':')[0]);
        let firstTimeMinute = parseInt(element1?.endTime?.split(':')[1]);
        let secondTimeHour = parseInt(mostRecent?.endTime?.split(':')[0]);
        let secondTimeMinute = parseInt(mostRecent?.endTime?.split(':')[1]);

        if (firstTimeHour <= secondTimeHour && firstTimeMinute <= secondTimeMinute) {
            mostRecent = element1;
        }
    }

    return mostRecent;
};
